:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f7f8fa;
  --color-gray-200: #f1f2f5;
  --color-gray-300: #e4e6eb;
  --color-gray-400: #bdbfc7;
  --color-gray-500: #8f94a2;
  --color-gray-600: #757a8a;
  --color-gray-650: #696e7c;
  --color-gray-700: #4f5461;
  --color-gray-800: #2c3242;
  --color-gray-900: #1b1f29;
  --color-blue-100: #ecf2ff;
  --color-blue-200: #d6e3ff;
  --color-blue-300: #bdd2ff;
  --color-blue-400: #175fff;
  --color-blue-500: #1043b2;
  --color-blue-transparent: rgba(236, 242, 255, 0.5);
  --color-violet-100: #f4effd;
  --color-violet-400: #6930db;
  --color-yellow-100: #fffbd8;
  --color-yellow-400: #f9cb40;
  --color-orange-100: #ffeed1;
  --color-orange-400: #ffa201;
  --color-orange-600: #ff7a1a;
  --color-red-100: #fff2f2;
  --color-red-200: #ffd3d3;
  --color-red-300: #ffbaba;
  --color-red-400: #cd3636;
  --color-red-500: #ae1d1d;
  --color-green-100: #e2f8ed;
  --color-green-200: #c5f2dc;
  --color-green-400: #3dd48a;
  --color-green-500: #36ba79;
  --color-green-600: #1f6e48;
  --color-primary-100: var(--color-blue-100);
  --color-primary-200: var(--color-blue-200);
  --color-primary-300: var(--color-blue-300);
  --color-primary-400: var(--color-blue-400);
  --color-primary-500: var(--color-blue-500);
  --color-text-dark: var(--color-gray-900);
  --color-text: var(--color-gray-800);
  --color-text-light: var(--color-gray-650);
  --color-text-disabled: var(--color-gray-400);
  --color-line: var(--color-gray-300);
  --color-line-light: var(--color-gray-200);
  --color-icon-fill: var(--color-gray-600);
  --color-button-primary-background: var(--color-primary-400);
  --color-button-primary-background-hover: var(--color-primary-500);
  --color-button-primary-text: var(--color-white);
  --color-button-primary-text-hover: var(--color-white);
  --color-button-secondary-text: var(--color-primary-400);
  --color-button-secondary-border: var(--color-gray-300);
  --color-button-secondary-background: var(--color-white);
  --color-button-secondary-text-hover: var(--color-primary-400);
  --color-button-secondary-background-hover: var(--color-primary-100);
  --color-button-text: var(--color-white);
  --color-button-text-hover: var(--color-white);
  --color-button-background: var(--color-primary-400);
  --color-button-background-hover: var(--color-primary-500);
  --color-menu-background-hover: var(--color-gray-200);
  --color-menu-item-highlight: var(--color-gray-300);
  --transition-duration: 0.2s;
  --transition-duration-long: 0.4s;
  --spacing-1: 0.0625rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-128: 8rem;
  --spacing-160: 10rem;
  --page-padding: var(--spacing-24);
  --page-padding-small: var(--spacing-16);
  --border-radius-small: 0.25rem;
  --border-radius: 0.5rem;
  --border-radius-big: 0.75rem;
  --input-height: var(--spacing-48);
  --input-height-small: var(--spacing-40);
  --input-height-tiny: var(--spacing-32);
  --input-border-radius: var(--border-radius-small);
  --button-height-small: var(--input-height-small);
  --button-height: var(--input-height);
  --button-border-radius: var(--input-border-radius);
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  --font-family-display: 'Larsseit', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  --font-size-smaller: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size: 0.875rem;
  --font-size-big: 1rem;
  --font-size-bigger: 1.125rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --font-size-heading-1: 2rem;
  --font-size-heading-2: 1.5rem;
  --font-size-heading-3: var(--font-size-bigger);
  --font-size-heading-4: var(--font-size-big);
  --line-height: 1.6;
  --line-height-tight: 1.25;
  --box-shadow: 0 0.0625rem 0.125rem rgba(44, 50, 66, 0.08);
  --box-shadow-long: 0 0 0.25rem rgba(44, 50, 66, 0.08), 0 0.25rem 1rem rgba(44, 50, 66, 0.08);
  --box-shadow-panel: 0 0 0.125rem 0.0625rem rgba(44, 50, 66, 0.08);
  --box-shadow-error: 0rem 0.0625rem 0.125rem var(--color-red-300);
  --z-index-header: 10;
  --z-index-skip-nav: 20;
  --z-index-events-popup: 2147483004;
  --z-index-tickets-bar: 2147483024;
  --z-index-tickets: 2147483034;
  --z-index-modal: 2147483044;
  --avatar-size-tiny: 1.5rem;
  --avatar-size-small: 2rem;
  --avatar-size-medium: 2.5rem;
  --avatar-size-large: 3rem;
  --avatar-size-huge: 3.375rem;
  --avatar-size-gigantic: 4rem;
  --button-size-small: 1.5rem;
  --button-size-medium: 2rem;
  --button-size-large: 3rem;
  --dash-nav-width: 16rem;
  --dash-nav-width-v2: 15rem;
  --color-tooltip: var(--color-white);
  --color-tooltip-background: var(--color-black);
  --tooltip-tail-size: 0.625rem;
  --tooltip: 100;
  --color-orange-custom: rgba(243, 156, 18, 1);
}

.select-dropdown {
  border: 2px solid orange;
  border-radius: 4px;
  min-height: 35px;
  min-width: 100px;
}

.pointer {
  cursor: pointer;
}

.pull-right {
  float: right !important;
}

.clearfix::after {
  clear: both;
}

/* Buttons */
.btn-outline-primary {
  color: rgba(243, 156, 18, 1) !important;
  border-color: rgba(243, 156, 18, 1) !important;
  background-color: white !important;
  box-shadow: none !important;
}

.btn-primary,
.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  color: rgba(243, 156, 18, 1) !important;
  border-color: rgba(243, 156, 18, 1) !important;
  background-color: rgba(243, 156, 18, 1) !important;
  box-shadow: none !important;
}

/* Margin */

/* Padding */
.pb-150 {
  padding-bottom: 150px !important;
}

/* Colors */
.text-primary {
  color: rgba(243, 156, 18, 1) !important;
}

.bg-primary {
  background-color: rgba(243, 156, 18, 1) !important;
}

/* Font sizes */
.font-large {
  font-size: large !important;
}
.font-larger {
  font-size: larger !important;
}
.font-x-large {
  font-size: x-large !important;
}

input {
  box-shadow: none !important;
}

/* Remove buttons inside input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
