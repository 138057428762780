/* Bottom Navbar */
.bottom-nav {
  position: fixed;
  height: 78px;
  bottom: 0;
  background-color: #f39c12;
  -webkit-box-shadow: 0px 1px 23px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 23px -5px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 23px -5px rgba(0, 0, 0, 0.5);
}

.bottom-nav .nav-link {
  width: 65px;
  height: 65px;
  background: #FFFFFF;
  align-self: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
}

.bottom-nav .nav-link a {
  text-decoration: none;
}

.bottom-nav .nav-link img.wrench {
  position: relative;
  left: -1px;
  top: 5px;
}

.bottom-nav .nav-link img.panel {
  position: relative;
  left: 1px;
  top: 6px;
}

.bottom-nav .nav-link img.cart {
  position: relative;
  left: 0px;
  top: 4px;
  width: 35px;
  height: 35px;
}

.bottom-nav .nav-link img.helmet {
  position: relative;
  left: -6px;
  top: 3px;
}

.bottom-nav .nav-link .active {
  font-size: 20px;
  font-weight: 900;
  color: #f39c12;
}
