.virtual-building-wrapper .page-header {
  position: fixed;
  background: white;
  box-shadow: none !important;
}

.toolbox-active {
  height: 40px;
  width: 40px;
  background-color: #f39c12;
  border-radius: 50%;
}

.toolbox-active img {
  position: relative;
  top: 5px;
}

/* Toolbox Items */
.toolbox-items-row {
  position: relative;
  top: 110px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  background-color: white;
  text-align: center;
  z-index: 9;
}

.btn-orange {
  color: #ffffff !important;
  border-color: #f39c12;
  background-color: #f39c12;
  box-shadow: none !important;
  outline: none !important;
}

.btn-orange-outline {
  color: #f39c12 !important;
  border-color: #f39c12;
  box-shadow: none !important;
  outline: none !important;
}


.toolbox-items-row button img {
  height: 32px;
  width: 32px;
  padding: 2px;
}

.toolbox-submenu {
  min-height: 100%;
  /* background-color: white;
  overflow-x: scroll; */
}

.virtual-building-wrapper #gridImg {
  padding-top: 80px;
}

.panel-image {
  height: 40px;
  width: 40px;
}

.selected-products {
  position: fixed;
  bottom: 10%;
  left: 50%;
  overflow: scroll;
  max-width: 100%;
  transform: translate(-50%, 0);
  background: var(--color-white);
}

.selected-products .selected-products-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
}

.selected-products .selected-products-list .product {
  padding: var(--spacing-8) var(--spacing-16);
  /* min-width: 10em; */
}

/* Filters page CSS */
.custom-checkbox {
  width: 15px;
  height: 15px;
  outline: 2px solid rgb(243, 156, 18);
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

/* Product page */
.product-page .sub-header {
  width: 100%;
  position: fixed;
  top: 3.7em;
  padding: var(--spacing-16) var(--spacing-16);
  background: var(--color-white);
  left: 50%;
  z-index: 999;
  transform: translate(-50%, 0);
}

.product-page .items-list {
  margin-top: var(--spacing-48);
}

.product-page .item-details {
  border: 0px;
}

.product-page .item-details input:focus,
.product-page .item-details input:active {
  border-color: none !important;
}
