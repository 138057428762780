@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {  }

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
}

@media only screen
and (max-width : 576px) {
  .virtual-building-wrapper .page-header {
    width: 100% !important;
  }

  .bottom-nav {
    width: 100vw !important;
  }
}

@media only screen
and (min-width : 576px) {
  .virtual-building-wrapper .page-header {
    min-width: 500px !important;
  }

  .bottom-nav {
    min-width: 500px !important;
  }
}

/* Desktops and laptops with below 7 ----------- */
@media only screen
and (min-width : 768px) {
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
}

/* Small-Medium screens ----------- */
@media only screen
and (min-width : 1324px) {
}

/* Medium screens ----------- */
@media only screen
and (min-width : 1524px) {
}

/* Medium-Large screens ----------- */
@media only screen
and (min-width : 1600px) {
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
}

/* iPhone 5 (landscape)----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
}

/* iPhone 5 (portrait)----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
}

/* Large screens ----------- */
@media only screen
and (min-width : 1700px) {
}

/* Ipad or greater height devices */
@media only screen
and (min-device-height: 1024px) {
}
